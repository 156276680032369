<template>
  <div class="admin-view-container">
    <div class="admin-table-search">
      <el-form
        ref="queryForm"
        :model="tableQuery"
        label-position="right"
        size="medium"
        @submit.native.prevent="search"
        v-loading="tableLoading"
        class="admin-tablequery">
        <el-form-item label="日志类型">
          <el-select v-model="tableQuery.logType" clearable>
            <el-option value="" label="全部"></el-option>
            <el-option v-for="item in $dict.logType" :key="item.value" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号">
          <el-input v-model="tableQuery.userName"></el-input>
        </el-form-item>
        <el-form-item style="margin-left: auto; text-align: right">
          <el-button size="medium" type="primary" :loading="tableLoading" native-type="submit" class="admin-btn">
            查询
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="admin-table-list" v-loading="tableLoading">
      <el-table :data="tableData.data" border height="100%">
        <el-table-column label="账号" prop="userName" width="150"></el-table-column>
        <el-table-column label="角色" prop="roleName" width="160"></el-table-column>
        <el-table-column
          label="操作"
          prop="operationType"
          width="150"
          :formatter="$formatter.dict($dict.operationType)"></el-table-column>
        <el-table-column label="板块" prop="module" width="200"></el-table-column>
        <el-table-column label="日志详情" prop="logDetails" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作时间" prop="createTime" width="180" :formatter="$formatter.date(true)"></el-table-column>
      </el-table>
      <div class="admin-pager-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tableQuery.page"
          :page-size="tableQuery.size"
          :total="tableData.total"
          :layout="tableConfig.layout"
          background></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {operationLogGetList} from '@/api/index';
import tableMinx from '@/mixin/table-page';
export default {
  mixins: [tableMinx(operationLogGetList)],
  data() {
    return {
      tableQuery: {
        logType: '',
        userName: '',
      },
    };
  },
  methods: {
    mixinParseData(data) {
      //mixin调用该方法 传入res.data.data数据 处理后返回data
      return data;
    },
  },
};
</script>

<style></style>
